import { URL_GL_UPLOADS } from "../Config/url_api";
import { postData } from "./tools";
import imageCompression from 'browser-image-compression';

const isImageFileType = (file) => {
    const type = file.type || file.mimetype;
    return ['image/png', 'image/PNG', 'image/jpg', 'image/JPG', 'image/jpeg', 'image/JPEG'].includes(type);
};

const uploadFile = (file, folderName) => {
    if (!folderName) throw Error("Missing target folder name");
    if ((file instanceof Blob) !== true) throw Error("Not an file");

    let formData = new FormData();
    formData.append('ldattuploads', file);
    return postData(`${URL_GL_UPLOADS}/attachment/${folderName}`, formData);
};

const uploadImageWithCompression = async (imageFile, folderName) => {
    if (!isImageFileType(imageFile)) throw Error("Hanya menerima file dengan format JPG, JPEG, atau PNG");

    const compressedFile = await imageCompression(imageFile, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1180,
        useWebWorker: true
    });

    const uploadRes = await uploadFile(new File([compressedFile], imageFile.name, {
        type: imageFile.type
    }), folderName);
    return uploadRes;
};

const newUploadFile = async (file, folderName, useImageCompression = false) => {
    if (!folderName) throw Error("Missing target folder name");
    if ((file instanceof Blob) !== true) throw Error("Not an file");

    if (useImageCompression) {
        if (isImageFileType(file)) {
            file = await imageCompression(file, {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1180,
                useWebWorker: true
            });

            file = new File([file], file.name, {
                type: file.type
            });
        }
    }

    let formData = new FormData();
    formData.append('ldattuploads', file);
    try {
        return await postData(`${URL_GL_UPLOADS}/attachment/${folderName}`, formData);
    } catch (error) {
        throw error;
    }
};

export {
    isImageFileType,
    newUploadFile,
    uploadFile,
    uploadImageWithCompression
};
import { ArrowBackRounded } from '@mui/icons-material';
import { IconButton, styled, Typography } from '@mui/material';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const Root = styled('div')(() => ({
    // marginBottom: 2,
    // marginTop: 3,
}));

const TitleWrapper = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: -14,
    [props.theme.breakpoints.down('sm')]: {
        marginLeft: -6
    }
}));

const TitleLabel = styled(Typography)((props) => ({
    fontWeight: 600,
    fontSize: '1.25rem',
    color: props.theme.palette.primary.main,
    [props.theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    }
}))

const PageHeaderWithNav = ({title}) => {
    const navigate = useNavigate();

    const onClickBackIcon = () => {
        navigate(-1);
    }

    return (
        <Root>
            <TitleWrapper>
                <IconButton
                 onClick={onClickBackIcon}
                >
                    <ArrowBackRounded/>
                </IconButton>
                {/* <Box display="flex" alignItems="baseline"> */}
                    <TitleLabel noWrap>{title}</TitleLabel>&nbsp;
                {/* </Box> */}
            </TitleWrapper>
            {/* <RenderIf isTrue={Boolean(subtitle)}>
                <Divider/>
                <Typography variant='subtitle2' color='text.secondary'>{subtitle}</Typography>
            </RenderIf> */}
        </Root>
    )
}

export default memo(PageHeaderWithNav);
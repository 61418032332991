import { DoDisturbAlt, LocalMallOutlined, RestorePage, WarningAmberRounded } from "@mui/icons-material";
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { DOCUMENT_FLOW_COLORS_STATUS, MODULE_CODES } from "../Config/enum";
import { ORIGIN } from "../Config/url_api";
import { isAcccountingAndSalesSPV, isAdminAPAR, isAdminSales, isSales, verifyAccount } from "./auth";
import { getCountReturnedPelunasanDocs } from "./pelunasan";
import { getListSOCount2 } from "./sales_order";
import { getData, putData } from "./tools";

const getSettingOptions = (modulecode, field, fieldAsQuery = false) => {
    return getData(ORIGIN + `/options/settings/${modulecode}/${fieldAsQuery ? "test" : field}`, false, 0, (fieldAsQuery) && {
        field
    });
};

const getUserAllModuleAccess = () => {
    return getData(ORIGIN + '/module/access/All');
};

const getDocumentFlow = (docno) => {
    const url_nya = '/documentflow';
    const searchParams = {
        oin: docno || "",
    };
    return getData(url_nya, false, 0, searchParams);
};

const getCurrentDocumentFlow = (docno) => {
    const url_nya = '/documentflow/current';
    url_nya.searchParams.set('oin', docno || "");
    const searchParams = {
        oin: docno
    };
    return getData(url_nya, false, 0, searchParams);
};

const getDocumentComments = async (docno) => new Promise((resolve) => {
    const url_nya = '/document/comments';
    const searchParams = {
        oin: docno,
    };

    getData(url_nya, false, 0, searchParams).then((result) => {
        resolve({
            datas: result,
            status: 'DONE',
        });
    }).catch((err) => {
        resolve({
            status: 'ERROR',
            error_status: (err.response && err.response.status) ? err.response.status : 500,
            datas: []
        })
    });
});

const getUserDashboardSettings = () => {
    return getData(ORIGIN + '/dashboard/settings');
};

const getDefaultUserNotification = async () => {
    const user = await verifyAccount();

    const result = [];

    if (isAdminSales(user.role) || isAcccountingAndSalesSPV(user.role)) {
        // Sales Order baru Masuk dan Sales Order Request Cancel Order
        // promises.push(getListSOCount2());
        const listCountSO = await getListSOCount2();
        if (listCountSO.New > 0) {
            result.push({
                icon: <LocalMallOutlined/>,
                title: 'New Sales Order',
                label: `Ada ${listCountSO.New} Sales Order yang masih belum diproses`,
                color: 'rgb(20, 55, 60)',
                url: '/SO/List/New',
            });
        }
        
        if (listCountSO['Request Cancel'] > 0) {
            result.push({
                icon: <WarningAmberRounded/>,
                title: 'Request Cancel Sales Order',
                label: `Ada ${listCountSO['Request Cancel']} Sales Order yang ingin di-Cancel`,
                color: 'rgb(231, 198, 13)',
                url: '/SO/List/Request%20Cancel',
            });
        }
    }

    if (isAdminAPAR(user.role) || isAcccountingAndSalesSPV(user.role)) {
        // PELUNASAN BARU MASUK
        const total_pelunasan_baru = await getPendingJoblistCount(MODULE_CODES.PELUNASAN_FAKTUR);
        if (total_pelunasan_baru) {
            result.push({
                icon: <FaMoneyCheckAlt/>,
                title: 'Approval Pembayaran Invoice',
                label: `Ada ${total_pelunasan_baru} pembayaran yg menunggu untuk diproses`,
                color: DOCUMENT_FLOW_COLORS_STATUS.OPEN,
                url: '/Pelunasan/List/OPEN',
            });
        }
    }

    if (isSales(user.role)) {
        // Sales Order yang di reject
        const [
            listCountSO,
            countResReturnedPelunasan
        ] = await Promise.all([
            getListSOCount2(),
            getCountReturnedPelunasanDocs()
        ]);

        if (listCountSO['Rejected Order']) {
            result.push({
                icon: <DoDisturbAlt/>,
                title: 'Rejected Sales Order',
                label: `Ada ${listCountSO['Rejected Order']} Sales Order yg di-Reject`,
                color: 'rgb(215, 74, 72)',
                url: '/SO/List/Rejected%20Order',
            })
        }

        if (countResReturnedPelunasan) {
            result.push({
                icon: <RestorePage/>,
                title: 'Pembayaran/Pelunasan Dikembalikan',
                label: `Ada ${countResReturnedPelunasan} Pembayaran/pelunasan yg dikembalikan oleh admin`,
                color: DOCUMENT_FLOW_COLORS_STATUS.RETURN,
                url: '/Pelunasan/List/RETURNED'
            });
        }
    }

    return result;
};

const getPendingJoblistCount = async (modulecode) => {
    const res = await getData(ORIGIN + "/documentflow/count/" + modulecode);
    return res.data;
};

const getNewAndUnreadNotifications = () => {
    return getData(ORIGIN + '/notification/UNREAD');
};

const checkNewNotification = async () => {
    const resp = await getData(ORIGIN + "/notification/checknewnotif");
    return resp.data;
};

const markAsReadedNotification = (id) => {
    return putData(`${ORIGIN}/notification/markasread?oin=${id}`);
}

export {
    checkNewNotification,
    getCurrentDocumentFlow,
    getDefaultUserNotification,
    getDocumentComments,
    getDocumentFlow,
    getNewAndUnreadNotifications,
    getPendingJoblistCount,
    getSettingOptions,
    getUserAllModuleAccess,
    getUserDashboardSettings,
    markAsReadedNotification
};
import React from 'react';
import asset from '../Lottie/not-found-404.json';
import Lottie from 'lottie-react';

const NotFound404 = ({
    loop=true,
    autoPlay= true,
    ...other
}) => {
  return (
      <Lottie
          animationData={asset}
          loop={loop}
          autoPlay={autoPlay}
          {...other}
      />
  )
};

export default NotFound404;

import {
    getData,
    isBetween,
    postData,
    putData
} from "./tools";
import {
    URL_SALES_ORDER, URL_SURAT_JALAN
} from '../Config/url_api';
import { STATUS_SO_ENUM } from "../Config/enum";

const url_approve_cancel_so = URL_SALES_ORDER + "/approve/cancelso";
const url_request_cancel_so = URL_SALES_ORDER + "/requestcancelso";
const url_remove_request_cancel_so = URL_SALES_ORDER + "/removerequestcancelso";

export const insertNewSO = async (json) => {
    const result = await postData(URL_SALES_ORDER + "/Create", json);
    return result;
};

export const editNewSO = async (json) => {
    const result = await putData(URL_SALES_ORDER + "/Edit", json);
    return result;
};

export const fetchAllSalesOrder = async () => {
    const res = await getData(URL_SALES_ORDER + "/list");
    return res;
};

export const fetchListSO = async (status) => {
    const res = await getData(`${URL_SALES_ORDER}/List/${status}`);
    return res;
};

export const fetchDetailSO = async (oin) => {
    const res = await getData(URL_SALES_ORDER + "/detail?oin=" + oin);
    return res;
};

export const processSalesOrder = async (orderno, status, data, expedition_id, formatSuratJalan, selected_adm_print, no_suratjalan, tanggal_suratjalan) => {
    let theUrl = null;
    let json = {};

    if (status === 'NEW') {
        theUrl = `${URL_SALES_ORDER}/process/newdocument`;
        json = {
            orderno
        }
    }
    if (['PROCESSED', 'PARTLY DELIVERED'].includes(status)) {
        // theUrl = theUrl = `${URL_SALES_ORDER}/process/flagamountdelivered`;
        theUrl = theUrl = `${URL_SURAT_JALAN}/Create`;
        json = {
            header: {
                orderno
            },
            details: data,
            expedition_id,
            module: formatSuratJalan,
            selected_adm_print,
            // no_suratjalan: no_suratjalan?.trim(),
            // createddate: tanggal_suratjalan
        }
    }
    if (!theUrl) throw Error('Missing status parameter');
    const res = await postData(theUrl, json);
    return res;
};

export const getDataForCreateSOPage = () => getData(URL_SALES_ORDER + "/dataforcreateso");

export const deleteSOBySales = async (orderno) => {
    const body = {
        oin: orderno
    };
    const res = await postData(URL_SALES_ORDER + '/delete', body);
    return res;
};

export const cancelSODirectlyByAdmin = async (orderno, status, reason) => {
    if (!orderno || !status) throw Error("Missing parameter");
    if (status === 'DELIVERED') throw Error("This Sales Order are already Delivered");

    const res = await postData(url_approve_cancel_so, {
        oin: orderno,
        direct: true,
        reason
    });
    return res;
};

export const requestCancelSOBySales = async (orderno, reason) => {
    const res = await postData(url_request_cancel_so, {
        oin: orderno,
        reason
    });
    return res;
};

export const removeRequestCancelSOBySales = async (orderno) => {
    const res = await postData(url_remove_request_cancel_so, {
        oin: orderno
    });
    return res;
};

export const cancelSOBySales = async (orderno, status) => {
    if (status !== STATUS_SO_ENUM.REJECTED_ORDER) throw Error("Sorry only rejected order can be canceled");

    const res = await postData(URL_SALES_ORDER + "/cancelso/bysales", {
        oin: orderno,
    });
    return res;
};

export const getListSOCount = async () => {
    try {
        const result = await getData(URL_SALES_ORDER + "/Listcount");
        return result;
    } catch (error) {
        return {};
    }
};

export const getListSOCount2 = async () => {
    const result = await getData(URL_SALES_ORDER + "/Listcount/New");
    return result;
};

export const rejectSalesOrder = (orderno, reason) => {
    const body = {
        oin: orderno,
        reason,
    };
    return postData(URL_SALES_ORDER + "/rejectorder", body);
};

export const getCountSalesCreateSOToday = () => {
    return getData(URL_SALES_ORDER + '/count/so/bysales');
};

export const getCountUnclosedSalesOrder = () => {
    return getData(URL_SALES_ORDER + '/count/unclosedoractive');
};

export const getOptionsForReportPage = () => {
    return getData(URL_SALES_ORDER + '/report/options');
};

export const generateReportSO = (body) => {
    return postData(URL_SALES_ORDER + "/report/generate", body)
};

export const getDashboardSalesOrder = (dashboard_name) => {
    return getData(URL_SALES_ORDER + '/dashboard?name=' + (dashboard_name || ""));
};

export const getColorForSOAges = (ages) => (ages <= 3) ? "#000" : "#fff";

export const getBgColorForSOAges = (ages) => {
    if (ages === null || ages === undefined) return null;
    if (ages <= 3) return null;
    if (isBetween(ages, 4, 7)) return "#11b310";
    return "#d93c3c"
}
import React, { useState } from 'react';
import { Badge, Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { DragHandleRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// const StyledBadge = styled(Badge)(({ theme }) => ({
//     '& .MuiBadge-badge': {
//       right: -8,
//       top: 8,
//       border: `2px solid ${theme.palette.background.paper}`,
//       padding: '0 4px',
//     },
//   }));

const SubMenuComponent = ({icon, label, url, onClick, subMenu, onCloseDrawer, nested=false, badge, padding}) => {
    const [open, setOpen] = useState(true);

    const isSubmenuExist = Array.isArray(subMenu);

    const paddingkiri = React.useMemo(() => {
        if (!nested) return 24;
        if (padding && nested) return padding + 12;
    }, [nested, padding])

    const handleOnClick = () => {
        if (onClick) return onClick();
        if (isSubmenuExist) {
            setOpen(!open);
            return;
        }
        onCloseDrawer();
    }

    return (
        <>
            <ListItemButton
             {...(url) ? {
                 component: Link,
                //  LinkComponent: Link,
                 to: url,
             } : {
                 component: 'li',
             }}
             sx={{ py: 0, minHeight: 32, color: 'rgba(0,0,0,0.6)'}}
             style={{
                 paddingLeft: paddingkiri,
                 paddingRight: 10
             }}
             onClick={handleOnClick}
            >
                <ListItemIcon sx={{color: 'inherit', minWidth: 34}}>
                    {icon || <DragHandleRounded/>}
                </ListItemIcon>
                {badge ?
                    <Badge badgeContent={badge} variant={(badge === 'dot') ? 'dot' : 'standard'} color='primary'>
                        <ListItemText
                         primary={label}
                         primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                        />
                    </Badge>
                :
                    <ListItemText
                     primary={label}
                     primaryTypographyProps={{ fontSize: 14, fontWeight: (isSubmenuExist) ? 600 : 500 }}
                    />
                }
                {isSubmenuExist ? (open) ? <ExpandLess/> : <ExpandMore/> : undefined}
            </ListItemButton>
            {isSubmenuExist && <Collapse in={open}>
                <div>
                    {React.Children.toArray(subMenu.map((subMenuProp) => (
                        <SubMenuComponent
                         label={subMenuProp.label}
                         onClick={subMenuProp.onClick}
                         subMenu={subMenuProp.subMenu}
                         icon={subMenuProp.icon}
                         url={subMenuProp.url}
                         onCloseDrawer={onCloseDrawer}
                         badge={subMenuProp.badge}
                         nested
                         padding={paddingkiri}
                        />
                    )))}
                </div>
            </Collapse>}
        </>
    )
}

export default React.memo(SubMenuComponent);
import { Error } from '@mui/icons-material';
import { alpha, Backdrop, Box, Button, createTheme, Grid, Paper, styled, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useRef } from 'react';
import CheckUser from '../../Asset/Animations/CheckUser';
import { login_action } from '../../Helpers/auth';
// import logo from '/favicon3.ico';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3b83f6',
            light: '#4ba1ff',
            dark: '#395ecf',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#683ab7',
            light: '#9675cd',
            dark: '#522da8',
            contrastText: '#ffffff'
        },
    },
});

const Root = styled(Box)(({
    theme
}) => ({
    minHeight: '100vh',
    width: '100%',
    background: alpha(theme.palette.secondary.light, 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center'
}));

const PaperComp = styled(Paper)(({
    theme
}) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
    background: theme.palette.background.paper,
    borderRadius: 10,
    maxWidth: 380,
    [theme.breakpoints.down('md')]: {
        minWidth: 0
    }
}));

const TitleWrapper = styled('div')(({
    theme
}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
}));

const Form = styled('form')(({
    theme
}) => ({
    marginTop: theme.spacing(1),
}));

const ErrorContainer = styled('label')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const LoginPage = () => {

    const usernameref = useRef(null);
    const passref = useRef(null);

    const [errorUsername, setErrorUsername] = React.useState(undefined);
    const [errPass, setErrPass] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        if (usernameref.current && passref.current) {
            setLoading(true);
            const username = usernameref.current.value;
            const password = passref.current.value;

            if (!username) {
                setLoading(false);
                return setErrorUsername(<ErrorContainer><Error fontSize='small'/>&nbsp;Username can not be empty</ErrorContainer>);
            }

            if (!password) {
                setLoading(false);
                return setErrPass(<ErrorContainer><Error fontSize='small'/>&nbsp;Password can not be empty</ErrorContainer>);
            }

            login_action(username, password).then((result) => {
                // if (result === "SUCCESS") {
                //     setTimeout(() => {
                //         setSuccessLogin(true);
                //     }, 2000);
                // }
            }).catch((err) => {
                setLoading(false);
                const response = (err.response && err.response.data) ? err.response.data : "Failed to login account";
                if (response === "Wrong password") return setErrPass(<ErrorContainer><Error fontSize='small'/>&nbsp;{response}</ErrorContainer>);
                setErrorUsername(<ErrorContainer><Error fontSize='small'/>&nbsp;{response}</ErrorContainer>);
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
        <Backdrop
         sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
         open={loading}
        >
            <CheckUser style={{
                width: 250
            }}/>
        </Backdrop>
        <Root>
            <PaperComp elevation={8}>
                <TitleWrapper>
                    {/* <Icon color='primary'><Diamond/></Icon> */}
                    {/* <img
                     src={'/logo192.png'}
                     alt='Logo'
                     width={50}
                    /> */}
                    <Typography variant='h6' style={{fontWeight: 'bold'}}>&nbsp;Login</Typography>
                </TitleWrapper>
                <Typography variant='h4' gutterBottom color='primary' style={{fontWeight: 'bold'}}>Hi, Welcome</Typography>
                <Typography variant='subtitle1' color="InactiveCaptionText">Enter your credentials to continue</Typography>
                <br/>

                <Typography variant='subtitle2'><b>Sign in with user account</b></Typography>
                
                <Form onSubmit={onSubmit}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                             variant='outlined'
                             label='Username'
                             placeholder='Type username here'
                             fullWidth
                             inputRef={usernameref}
                             error={Boolean(errorUsername)}
                             helperText={errorUsername}
                             onChange={() => {setErrorUsername(undefined)}}
                             InputProps={{
                                 style: {
                                     borderRadius: 10,
                                 },
                             }}
                             inputProps={{
                                 autoCapitalize: 'none'
                             }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                            variant='outlined'
                            label='Password'
                            placeholder='Type password here'
                            type="password"
                            fullWidth
                            inputRef={passref}
                            error={Boolean(errPass)}
                            helperText={errPass}
                            onChange={() => {setErrPass(undefined)}}
                            InputProps={{
                                style: {
                                    borderRadius: 10
                                }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type='submit' disabled={loading} style={{textTransform: 'none', borderRadius: 10}} variant='contained' color='primary' fullWidth>Sign In</Button>
                        </Grid>
                    </Grid>
                </Form>
            </PaperComp>
        </Root>
        </ThemeProvider>
    )
}

export default LoginPage
import {
    ORIGIN,
    URL_LOGIN
} from "../Config/url_api";
import {
    getData,
    postData,
    putData
} from "./tools";
import Cookies from 'universal-cookie';

const login_action = async (username, password) => {
    const response = await postData(URL_LOGIN, {
        username,
        password
    });

    const cookies = new Cookies();
    cookies.set('ld_token', `Bearer ${response}`, {
        maxAge: 60 * 60 * 20,
        path: "/"
    });
    cookies.set('ld_last_log_time', new Date().getTime(), {
        maxAge: 60 * 60 * 20,
        path: "/"
    });

    const targetUrl = sessionStorage.getItem("_target_url") || "/";
    sessionStorage.removeItem("_target_url");
    window.location.replace(targetUrl);
};

function getYesterdayDate() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
}

const logout_action = () => {
    const cookies = new Cookies();
    const yesterdayDate = getYesterdayDate();
    cookies.set('ld_token', '', {
        expires: yesterdayDate,
        path: "/"
    });
    window.location.reload();
};

const verifyAccount = async () => {
    const res = await getData(ORIGIN + "/verify/NEW/v2");
    return res;
};

const getListAllUser = () => {
    return getData(ORIGIN + "/user");
};

const registerNewAccount = (username,
    role,
    name,
    password) => {
    if (username.length > 10) throw Error('username max 10 characters');

    return postData(ORIGIN + "/register", {
        username,
        role,
        name,
        password
    });
};

const updateUserData = ({
    username,
    role,
    name
}) => {
    if (username.length > 10) throw Error('username max 10 characters');

    return putData(ORIGIN + "/user", {
        username,
        role,
        name
    });
};

const changePasswordUser = (username, new_password) => {
    return putData(ORIGIN + "/user/password", {
        username,
        password: new_password
    })
};

function isMaster (role) {
    return role === 'MASTER';
}

function isAdminSales(role = '') {
    return ['MASTER', 'ADM_SALES'].includes(role);
}

function isAdminSalesOnly(role) {
    return role === 'ADM_SALES';
}

function isAdminAPAR(role = '') {
    return ['MASTER', 'ADM_AP_AR'].includes(role);
}

function isAdminWarehouse(role = '') {
    if (typeof role !== 'string') return role;
    return ['MASTER', 'ADM_WH'].includes(role);
}

function isAdminWarehouseOnly(role = '') {
    if (typeof role !== 'string') return role;
    return 'ADM_WH' === role;
}

function isAcccountingAndSalesSPV(role = "") {
    return ['MASTER', 'ACC_SALES_SPV'].includes(role);
}

function isAcccountingAndSalesSPVOnly(role) {
    return role === 'ACC_SALES_SPV';
}

function isSales(role = '') {
    return ['MASTER', 'SALES'].includes(role);
}

function isSalesSPV(role = '') {
    return ['MASTER', 'SALES_SPV'].includes(role);
}

function isSalesOnly(role) {
    return role === "SALES";
}

function isSalesSPVOnly(role) {
    return role === "SALES_SPV";
}

function checkUserModuleAuth (modulecode) {
    return getData(ORIGIN + "/module/check/" + modulecode);
}

export {
    changePasswordUser,
    checkUserModuleAuth,
    getListAllUser,
    isAcccountingAndSalesSPV,
    isAcccountingAndSalesSPVOnly,
    isAdminAPAR,
    isAdminSales,
    isAdminSalesOnly,
    isAdminWarehouse,
    isAdminWarehouseOnly,
    isMaster,
    isSales,
    isSalesOnly,
    isSalesSPVOnly,
    isSalesSPV,
    login_action,
    logout_action,
    registerNewAccount,
    updateUserData,
    verifyAccount
}
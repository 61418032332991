import { Button, styled } from '@mui/material';
import React from 'react';
import NotFound404 from '../../Asset/Animations/NotFound404';
import { Link } from 'react-router-dom';

const Body = styled("div")(() => ({
    height: 'calc(100vh - 64px * 2)',
    maxHeight: 'calc(100vh - 170px)',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center'
}));

const PageWrapper = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 2)
}));

const NotFoundPage = () => {
    React.useEffect(() => {
        document.title = 'Not Found';
    }, []);

    return (
        <Body>
            <PageWrapper>
                <NotFound404 style={{width: "100%", maxWidth: 600}}/>
                <Button component={Link} to='/' variant='contained' disableElevation>Back To Home</Button>
            </PageWrapper>
        </Body>
    )
}

export default NotFoundPage;
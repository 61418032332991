import {
    Children,
    Fragment,
    memo,
    useEffect,
    useState
} from 'react'
import {
    alpha,
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    Grow,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import { getDefaultUserNotification, getNewAndUnreadNotifications, markAsReadedNotification } from '../../Helpers/option_settings';
import { Link } from 'react-router-dom';
import { URL_GL_UPLOADS } from '../../Config/url_api';

const NotificationComponent = ({closeDrawer}) => {

    const [state, setState] = useState({
        status: 'LOADING',
        data: [],
    });
    const [doFetch, setDoFetch] = useState(true);

    const [notifications, setNotifications] = useState({
        statusFetch: 'LOADING',
        data: [],
    });
    const [doFetchNotification, setDoFetchNotification] = useState(true);

    useEffect(() => {
        if (!doFetch) return;

        let unmount = false;
        setState(prev => ({
            ...prev,
            status: 'LOADING',
            data: [],
        }));

        getDefaultUserNotification().then((result) => {
            if (unmount) return;
            setState(prev => ({
                ...prev,
                status: 'DONE',
                data: result
            }));
            setDoFetch(false);
        }).catch((err) => {
            if (unmount) return;
            setState(prev => ({
                ...prev,
                status: 'ERROR'
            }));
            setDoFetch(false);
        });

        return () => {
            unmount = true;
        }
    }, [doFetch]);

    useEffect(() => {
        if (!doFetchNotification) return;

        let unmount = false;
        setNotifications(prev => ({
            ...prev,
            statusFetch: 'LOADING',
            data: [],
        }));

        getNewAndUnreadNotifications().then((result) => {
            if (unmount) return;
            setNotifications(prev => ({
                ...prev,
                statusFetch: 'DONE',
                data: result,
            }));
            setDoFetchNotification(false);
        }).catch((err) => {
            if (unmount) return;
            setNotifications(prev => ({
                ...prev,
                statusFetch: 'ERROR',
            }));
            setDoFetchNotification(false);
        });

        return () => {
            unmount = true;
        };
    }, [doFetchNotification]);

    const onClickDefaultNotification = () => {
        closeDrawer();
    };

    const onClickNotifications = (id) => () => {
        markAsReadedNotification(id);
        closeDrawer();
    };

    return (
        <Box
         sx={{
             width: {
                 xs: 300,
                 sm: 500,
                 md: 500,
                 lg: 500,
                 xl: 500
             },
             minHeight: 100,
         }}
        >
            <Grid container spacing={1} direction='column'>
                <Grid item>
                    <Box display='flex' flexDirection='row' padding='16px 16px 16px' alignItems='center'>
                        <Typography fontWeight={600} variant='subtitle1' component='h6'>All Notification</Typography>
                        <Chip
                         size='small'
                         variant='filled'
                         label={(state.data.length + notifications.data.length).toString()}
                         color='secondary'
                         sx={{
                            margin: '0px 0px 0px 16px',
                         }}
                        />
                    </Box>
                </Grid>
                <Divider/>
                <Grid item>
                    <div
                     style={{
                         height: '100%',
                         overflowX: 'hidden',
                         maxHeight: 'calc(100vh - 162px)',
                         overflowY: 'auto',
                     }}
                    >
                        <Grow in={state.status === 'LOADING'} key='loading-notification-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <Box display='flex' justifyContent='center' padding={'0px 0px 24px'}><CircularProgress size={32}/></Box>
                        </Grow>

                        <Collapse in={state.status === 'ERROR'} key='errot-notification-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' padding={'0px 0px 24px'}>
                                <Typography gutterBottom>Error fetching data</Typography>
                                <Button
                                 onClick={() => {setDoFetch(true)}}
                                 size='small'
                                 color='error'
                                 variant='contained'
                                 style={{
                                    borderRadius: 10,
                                    textTransform: 'none'
                                 }}
                                >Try Again</Button>
                            </Box>
                        </Collapse>

                        <Collapse in={state.status === 'DONE'} key='success-notification-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <List
                             sx={{
                                width: '100%',
                             }}
                             disablePadding
                            >
                                {Children.toArray(state.data.map(({icon, title, label, color, url}) => (
                                    <Fragment>
                                        <ListItem alignItems='flex-start' disablePadding>
                                            <ListItemButton
                                             {...(Boolean(url)) && {
                                                component: Link,
                                                to: url
                                             }}
                                             onClick={onClickDefaultNotification}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                    alt={title}
                                                    {...(Boolean(color)) && {
                                                        sx: {
                                                            bgcolor: alpha(color, 0.2),
                                                            color: color
                                                        }
                                                    }}
                                                    >
                                                        {icon}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                primary={title}
                                                secondary={label}
                                                secondaryTypographyProps={{
                                                    fontSize: 13
                                                }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Fragment>
                                )))}
                            </List>
                        </Collapse>

                        <Divider/>
                        <Grow in={notifications.statusFetch === 'LOADING'} key='loading-notification2-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <Box display='flex' justifyContent='center' padding={'0px 0px 24px'}><CircularProgress size={32}/></Box>
                        </Grow>
                        <Collapse in={notifications.statusFetch === 'ERROR'} key='errot-notification2-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' padding={'0px 0px 24px'}>
                                <Typography gutterBottom>Error fetching data</Typography>
                                <Button
                                 onClick={() => {setDoFetchNotification(true)}}
                                 size='small'
                                 color='error'
                                 variant='contained'
                                 style={{
                                    borderRadius: 10,
                                    textTransform: 'none'
                                 }}
                                >Try Again</Button>
                            </Box>
                        </Collapse>
                        <Collapse in={notifications.statusFetch === 'DONE' && notifications.data.length > 0} key='success-notification2-app' mountOnEnter unmountOnExit timeout={{appear: 400, enter: 400, exit: 0}}>
                            <List
                             sx={{
                                width: '100%',
                             }}
                             disablePadding
                            >
                                {Children.toArray(notifications.data.map(({title, label, avatar, url, id, type}) => (
                                    <Fragment>
                                        <ListItem alignItems='flex-start' disablePadding>
                                            <ListItemButton
                                             {...(Boolean(url)) && {
                                                component: Link,
                                                to: url
                                             }}
                                             onClick={onClickNotifications(id)}
                                            >
                                                <ListItemAvatar>
                                                    {(Boolean(avatar)) ? (
                                                        <Avatar src={`${URL_GL_UPLOADS}${avatar}`} alt={type}/>
                                                    ) : (<Avatar>{type.charAt(0)}</Avatar>)}
                                                </ListItemAvatar>
                                                <ListItemText
                                                 primary={title}
                                                 secondary={label}
                                                 secondaryTypographyProps={{
                                                     fontSize: 13
                                                 }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Fragment>
                                )))}
                            </List>
                        </Collapse>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default memo(NotificationComponent);
import { CircularProgress } from "@mui/material";
import ErrorBoundary from "Components/ErrorBoundary";
import { CenterComponent } from "Components/Utilites";
import NotFoundPage from "Page/NotFoundPage";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const PageCreateSalesOrder = lazy(() => import('./CreateSalesOrderPage'));
// const PageCreateSalesOrderNew = lazy(() => import('./CreateSalesOrderPage2'));
const PageDetailSalesOrder = lazy(() => import('./DetailSalesOrderPage'));
const PageSalesOrderList = lazy(() => import('./SalesOrderListPage'));
const PageEditSalesOrder = lazy(() => import('./EditSalesOrderPage'));
const ReportSalesOrderPage = lazy(() => import('./ReportSalesOrderPage'));
const ListOrderForWarehousePage = lazy(() => import('./ListOrderForWarehousePage'));

const FallBackComponent = () => (
    <CenterComponent>
        <CircularProgress/>
    </CenterComponent>
)

const SalesOrderRoute = () => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<FallBackComponent/>}>
                <Routes>
                    <Route path="create" element={<PageCreateSalesOrder/>}/>
                    {/* <Route path="create_new" element={<PageCreateSalesOrderNew/>}/> */}
                    <Route path="List/:status" element={<PageSalesOrderList/>}/>
                    <Route path="Detail" element={<PageDetailSalesOrder/>}/>
                    <Route path="Edit" element={<PageEditSalesOrder/>}/>
                    <Route path="report" element={<ReportSalesOrderPage/>}/>
                    <Route path="listwh/:status" element={<ListOrderForWarehousePage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </Suspense>
        </ErrorBoundary>
    )
}

export default SalesOrderRoute
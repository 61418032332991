import {
    AccountBalanceRounded,
    AccountBox,
    AddCardRounded, Assessment,
    ClearRounded,
    CreateRounded,
    DashboardOutlined,
    EmojiPeopleRounded,
    EmojiTransportation,
    KeyboardArrowDown,
    LocalShipping,
    LocationCity,
    LockRounded,
    LogoutRounded,
    MenuRounded,
    MoreVert,
    NoteAddRounded,
    NotificationsOutlined,
    PaymentsRounded,
    PeopleAlt,
    SearchRounded,
    Store,
    SummarizeRounded
} from '@mui/icons-material';
import {
    AppBar,
    Badge,
    Box,
    CircularProgress,
    Collapse,
    createTheme,
    CssBaseline,
    Divider,
    Drawer,
    GlobalStyles,
    Hidden,
    IconButton,
    InputAdornment,
    lighten,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popover,
    styled,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography
} from '@mui/material';
import BackNavWithTitle from 'Components/AppBarWithDrawer/BackNavWithTitle';
import NotificationComponent from 'Components/AppBarWithDrawer/NotificationComponent';
import SubMenuComponent from 'Components/AppBarWithDrawer/SubMenuComponent';
import { logout_action, verifyAccount } from 'Helpers/auth';
import { searchUserMenu } from 'Helpers/m_menu';
import { checkNewNotification } from 'Helpers/option_settings';
import { getErrorFetchResponse } from 'Helpers/tools';
import { Children, useEffect, useMemo, useRef, useState } from 'react';
import { BiListCheck } from 'react-icons/bi';
import { BsClipboard, BsClipboardPlus, BsListUl } from 'react-icons/bs';
import { FaBoxes, FaFileInvoiceDollar, FaListAlt } from 'react-icons/fa';
import { GiReturnArrow } from 'react-icons/gi';
import { IoReceipt } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const globalScrollbarStyles = {
    '*::-webkit-scrollbar': {
      width: '8px',  // Lebar scrollbar vertikal
      height: '8px', // Tinggi scrollbar horizontal
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',  // Warna track scrollbar
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',  // Warna scrollbar
      borderRadius: '4px',      // Radius agar ujungnya halus
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',  // Warna scrollbar saat di-hover
    },
  };

const MenuDrawerList = styled(List)((props) => ({
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: `calc(100vh - 90px)`,
    scrollbarGutter: 'stable',
    overflow: 'auto',
    // "&:hover": {
    //     overflow: 'auto'
    // },
    // [props.theme.breakpoints.down('md')]: {
    //     overflow: 'auto'
    // }
}));

const NavigationRightDrawer = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

const Toolbar = styled("div")(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    paddingTop: 6,
    // paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}))

const menuDrawerWidth = 256;
const toolbarHeight = 87;

const MenuDrawer = ({
    listItems={},
    onClose
}) => {
    const searchRef = useRef();
    const [openMenu, setOpenMenu] = useState({});
    const [searchValue, setSearchValue] = useState('');

    const menuUnextendSecondaryLabel = (() => {
        const result = {};
        Object.keys(listItems).forEach(menuName => {
            result[menuName] = Array.isArray(listItems[menuName]) ? listItems[menuName].map(subMenu => subMenu.label).join(", ") : null;
        });
        return result;
    })();

    const filteredMenus = (() => {
        const trimedValue = searchValue.trim().toLowerCase();
        if (!listItems) return {};
        if (!trimedValue) return listItems;

        const filteredResult = {};
        Object.keys(listItems).forEach(applicationName => {
            if (applicationName === 'Account') {
                filteredResult[applicationName] = listItems[applicationName];
                return;
            }
            if (applicationName.toLowerCase().includes(trimedValue)) {
                filteredResult[applicationName] = listItems[applicationName];
                return;
            }
            const arr1 = listItems[applicationName];
            arr1.forEach(item1 => {
                // jika top level label ada langsung masukin semua termasuk subMenu nya juga
                if (item1.label.toLowerCase().includes(trimedValue)) {
                    if (!filteredResult[applicationName]) {
                        filteredResult[applicationName] = [];
                    }
                    filteredResult[applicationName].push(item1);
                }
                // kalo tidak ada cek ada subMenu nya gk, kalo ada cek lagi label nya
                else {
                    if (item1.subMenu) {
                        let tempSubMenu = item1.subMenu.filter(sub => sub.label.toLowerCase().includes(trimedValue));
                        if (tempSubMenu.length) {
                            if (!filteredResult[applicationName]) {
                                filteredResult[applicationName] = [{
                                    ...item1,
                                    subMenu: tempSubMenu
                                }];
                            }
                        }
                    }
                }
            })
        });
        return filteredResult;
    })();

    const arrayKeyFilteredMenus = Object.keys(filteredMenus);

    // useEffect(() => {
    //     const handleOnKeyDown = (env) => {
    //         const key = env.key;
    //         if (key && key.length === 1 && key.match(/[a-z]/i)) {
    //             if (searchRef.current) {
    //                 searchRef.current.focus();
    //             }
    //         }
    //     };
    //     document.addEventListener('keydown', handleOnKeyDown);
    //     return () => {
    //         document.removeEventListener('keydown', handleOnKeyDown);
    //     }
    // }, []);

    useEffect(() => {
        // DEFAULT KETIKA DRAWER TERBUKA BUKA SEMUA EXPAND MENU
        const result = {};
        Object.keys(listItems).forEach(key => {
            result[key] = true;
        });
        setOpenMenu(result);
    }, [listItems]);

    const onClickMenu = (menuName) => (e) => {
        setOpenMenu(prev => {
            const res = {
                ...prev
            };
            res[menuName] = !res[menuName];
            return res;
        })
    };

    let timeout = null;
    const onChangeSearchValue = (e) => {
        const value = e.target.value;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchValue(value);
        }, 500);
    };

    const onClickClearSearch = () => {
        if (searchRef.current) {
            searchRef.current.value = "";
            setSearchValue("");
        }
    };

    return (
        <div>
            <ThemeProvider
            theme={createTheme({
                components: {
                    MuiListItemButton: {
                        defaultProps: {
                            disableTouchRipple: true,
                        }
                    }
                },
            })}
            >
            <Box
             px={2}
             py={4}
             height={toolbarHeight}
             borderBottom={theme => `1px solid ${theme.palette.divider}`}
            >
                <TextField
                label='Pencarian Menu'
                placeholder='Cari menu disini'
                defaultValue={searchValue}
                onChange={onChangeSearchValue}
                color='primary'
                size='small'
                InputProps={{
                    inputRef: searchRef,
                    startAdornment: (
                    <InputAdornment position='start'>
                        <SearchRounded fontSize='small'/>
                    </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                            onClick={onClickClearSearch}
                            size='small'
                            ><ClearRounded fontSize='small'/></IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        borderRadius: 8,
                        fontSize: 14
                    }
                }}
                />
            </Box>
            <MenuDrawerList>
                {(arrayKeyFilteredMenus.length === 0) ? (
                    <ListItem>
                        <Typography variant='caption' color='GrayText'><em>menu tidak ditemukan</em></Typography>
                    </ListItem>
                ) : Children.toArray(arrayKeyFilteredMenus.map((menuName) => (
                    <>
                        <Box
                        sx={{
                            pb: openMenu[menuName] ? 2 : 0,
                        }}
                        >
                            <ListItemButton
                                alignItems='flex-start'
                                onClick={onClickMenu(menuName)}
                                sx={{
                                    px: 3,
                                    pt: 2.5,
                                    pb: openMenu[menuName] ? 0 : 2.5,
                                    '&:hover, &:focus': {
                                        '& svg': {
                                            opacity: openMenu[menuName] ? 1 : 0
                                        }
                                    }
                                }}
                            >
                                <ListItemText
                                primary={menuName}
                                primaryTypographyProps={{
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    lineHeight: '20px',
                                    mb: '2px'
                                }}
                                secondary={menuUnextendSecondaryLabel[menuName]}
                                secondaryTypographyProps={{
                                    noWrap: true,
                                    fontSize: 12,
                                    lineHeight: '16px',
                                    sx:{
                                    color: openMenu[menuName] ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.6)',
                                    transition: '0.4s'
                                    }
                                }}
                                sx={{ my: 0 }}
                                />
                                <KeyboardArrowDown
                                    sx={{
                                        mr: -1,
                                        opacity: 0,
                                        transform: openMenu[menuName] ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '0.2s'
                                    }}
                                />
                            </ListItemButton>

                            <Collapse in={openMenu[menuName]} mountOnEnter unmountOnExit>
                                <div>
                                    {Children.toArray(filteredMenus[menuName].map(({label, url, onClick, icon, subMenu, badge}) => (
                                        <SubMenuComponent
                                        icon={icon}
                                        label={label}
                                        onClick={onClick}
                                        subMenu={subMenu}
                                        url={url}
                                        onCloseDrawer={onClose}
                                        badge={badge}
                                        />
                                    )))}
                                </div>
                            </Collapse>
                        </Box>
                        <Divider/>
                    </>
                )))}
            </MenuDrawerList>
          </ThemeProvider>
        </div>
    )
};

const RightDrawer = ({open, onClose, currentName, handleLogout}) => {
    const onClick = () => {
        onClose();
    };

    const onClickLogout = () => {
        handleLogout();
        onClose();
    };

    return (
        <Drawer open={open} onClose={onClose} anchor='right'>
            <Box role='presentation'>
                <Paper elevation={0} sx={{maxWidth: 256, width: 256}}>
                    <NavigationRightDrawer component='nav' sx={{pt: 0}}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <EmojiPeopleRounded/>
                                </ListItemIcon>
                                <ListItemText
                                 primary={<>Hello&nbsp;<b>{currentName}</b></>}
                                />
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText
                                 primary='Menu'
                                 primaryTypographyProps={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                 }}
                                />
                            </ListItem>
                            <ListItem disablePadding><ListItemButton component={Link} to='/Dashboard' onClick={onClick} sx={{pt: 0}}>
                                <ListItemIcon><DashboardOutlined/></ListItemIcon>
                                <ListItemText primary='Dashboard' primaryTypographyProps={{fontSize: 14}}/>
                            </ListItemButton></ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText
                                 primary='User'
                                 primaryTypographyProps={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                 }}
                                />
                            </ListItem>
                            <ListItem disablePadding><ListItemButton component={Link} to='/Account/Password' onClick={onClick} sx={{py: 0}}>
                                <ListItemIcon><LockRounded/></ListItemIcon>
                                <ListItemText primary='Change Password' primaryTypographyProps={{fontSize: 14}}/>
                            </ListItemButton></ListItem>
                            <ListItem disablePadding><ListItemButton sx={{py: 0}} onClick={onClickLogout}>
                                <ListItemIcon><LogoutRounded/></ListItemIcon>
                                <ListItemText primary='Logout' primaryTypographyProps={{fontSize: 14}}/>
                            </ListItemButton></ListItem>
                        </List>
                    </NavigationRightDrawer>
                </Paper>
            </Box>
        </Drawer>
    );
};

const MainDrawer = (props) => {
    // const { window } = props;

    // OLD STATE
    const [doFetchMenu, setDoFetchMenu] = useState(true);
    const [currentName, setCurrentName] = useState(null);
    const [isNewNotif, setIsNewNotif] = useState(false);
    const [anchorElNotifiaction, setAnchorElNotifiaction] = useState(null);
    const [openRightDawer, setOpenRightDawer] = useState(false);
    const [userMenu, setUserMenu] = useState({
        status: 'LOADING',
        data: {},
        error: {
            statusCode: 0,
            message: null
        }
    });

    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const currentYear = useMemo(() => new Date().getFullYear(), []);

    const getMenuIcon = (icon_name) => {
        switch (icon_name) {
            case 'CreateIcon':
                return <CreateRounded/> ;
            case 'LocalShippingIcon':
                return <LocalShipping/> ;
            case 'StoreIcon':
                return <Store/> ;
            case 'LocationCity':
                return <LocationCity/> ;
            case 'AccountBox':
                return <AccountBox/> ;
            case 'AccountBalanceRounded':
                return <AccountBalanceRounded/> ;
            case 'NoteAddRounded':
                return <NoteAddRounded/> ;
            case 'SummarizeRounded':
                return <SummarizeRounded/> ;
            case 'EmojiTransportation':
                return <EmojiTransportation/> ;
            case 'AddCardRounded':
                return <AddCardRounded/> ;
            case 'PaymentsRounded':
                return <PaymentsRounded/> ;
            case 'FaBoxes':
                return <FaBoxes/> ;
            case 'FaListAlt':
                return <FaListAlt/> ;
            case 'FaFileInvoiceDollar':
                return <FaFileInvoiceDollar/> ;
            case 'GiReturnArrow':
                return <GiReturnArrow/> ;
            case 'IoReceipt':
                return <IoReceipt/> ;
            case 'BiListCheck':
                return <BiListCheck/> ;
            case 'BsClipboard':
                return <BsClipboard/> ;
            case 'BsClipboardPlus':
                return <BsClipboardPlus/> ;
            case 'BsListUl':
                return <BsListUl/> ;
            case 'AssessmentIcon':
                return <Assessment/> ;
            case 'PeopleAlt':
                return <PeopleAlt/> ;
            default:
                return null;
        }
    };

    const handleLogout = () => {
        logout_action();
    };

    const fetchNotifCheck = () => {
        checkNewNotification().then((result) => {
            setIsNewNotif(result);
        }).catch((err) => {
            setIsNewNotif(false);
        });
    };

    useEffect(() => {
        verifyAccount().then((result) => {
            setCurrentName(result.name);
        }).catch((err) => {
            const {
                message
            } = getErrorFetchResponse(err);
            const data = (err && err.response && err.response.data) ? err.response.data : {};
            alert(`Gagal Verifikasi user, silahkan coba lagi sebentar lagi "${message}"`);

            if (data.logout) {
                handleLogout();
            }
        });

        fetchNotifCheck();
    }, []);

    useEffect(() => {
        if (!doFetchMenu) return;
        let unmount = false;

        setUserMenu({
            data: {},
            status: 'LOADING',
            error: {
                statusCode: 0,
                message: null
            }
        });
        searchUserMenu("", true).then((result) => {
            if (unmount) return;
            setUserMenu({
                status: 'OK',
                data: result
            });
        }).catch((err) => {
            if (unmount) return;
            setUserMenu(prev => ({
                ...prev,
                status: 'ERROR',
                error: getErrorFetchResponse(err)
            }));
        }).finally(() => {
            if (unmount) return;
            setDoFetchMenu(false);
        });

        return () => {
            unmount = true;
        };
    }, [doFetchMenu]);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawer = (() => {
        if (userMenu.status === "LOADING") return (
            <Box p={2}>
                <CircularProgress/>
            </Box>
        )
        if (userMenu.status === "ERROR") return (
            <Box p={2}>
                <Typography variant='h6' fontWeight={600} color="error">ERROR!!!</Typography>
                <Typography variant='subtitle1' color="GrayText">{userMenu.error.message}</Typography>
                <Typography variant='subtitle2' color="GrayText">Coba untuk lakukan refresh aplikasi</Typography>
            </Box>
        )
        if (userMenu.status === "OK") {
            const getListItems = () => {
                const result = {};
                Object.keys(userMenu.data).forEach(sectionLabel => {
                    result[sectionLabel] = userMenu.data[sectionLabel].map(menu => ({
                        ...menu,
                        icon: getMenuIcon(menu.icon),
                        ...(menu.subMenu) ? {
                            subMenu: menu.subMenu.map(sb => ({
                                ...sb,
                                icon: getMenuIcon(sb.icon),
                            }))
                        } : {}
                    }))
                });
                return result;
            }
            return (
                <MenuDrawer
                 listItems={getListItems()}
                 onClose={handleDrawerClose}
                />
            )
        }
    })()

    // Remove this const when copying and pasting into your project.
    // const container = window !== undefined ? () => window().document.body : undefined;

    return <>
        <CssBaseline/>
        <GlobalStyles styles={globalScrollbarStyles}/>
        <Box
         sx={{
            display: 'flex',
         }}
        >
            <AppBar
            position="fixed"
            elevation={0}
            sx={{
                background: theme => theme.palette.background.paper,
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
                color: theme => theme.palette.text.primary,
                width: {
                    md: `calc(100% - ${menuDrawerWidth}px)`
                },
                ml: {
                    md: `${menuDrawerWidth}px`
                },
                height: toolbarHeight
            }}
            >
                <Toolbar>
                    <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuRounded/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" fontWeight={600} fontSize='1rem'>
                        LD Portal
                    </Typography>
                    <Box marginLeft='auto' display='flex' alignItems='center'>
                        <Hidden smDown>
                            <Typography marginRight='10px' fontWeight={600} variant='body2'>{currentName}</Typography>
                        </Hidden>

                        <Tooltip title='open notification'>
                            <IconButton
                                onClick={(e) => {setAnchorElNotifiaction(e.currentTarget)}}
                                disableRipple
                                sx={{
                                mr: 1,
                                borderRadius: '8px',
                                background: theme => lighten(theme.palette.primary.light, 0.7),
                                transition: 'all 0.2s ease-in-out 0s',
                                "&:hover": {
                                    background: theme => theme.palette.primary.main,
                                    color: '#fff'
                                }
                                }}
                                color='primary'
                            >
                                <Badge
                                color='warning'
                                variant='dot'
                                invisible={isNewNotif === false}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: 4,
                                        top: 6,
                                        border: `2px solid rgb(203, 238, 233)`,
                                        padding: '0 2px',
                                    }
                                }}
                                >
                                    <NotificationsOutlined fontSize='small'/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='open extra menu'>
                            <IconButton
                            color='inherit'
                            edge='end'
                            onClick={() => {setOpenRightDawer(true)}}
                            >
                                <MoreVert/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
                <BackNavWithTitle/>
            </AppBar>

            <Box
            component="nav"
            sx={{
                width:{
                    md: menuDrawerWidth
                },
                flexShrink: {
                    md: 0
                }
            }}
            aria-label="menu folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* MOBILE */}
                <Drawer
                // container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: menuDrawerWidth },
                }}
                >
                    {drawer}
                </Drawer>

                {/* DESKTOP */}
                <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: menuDrawerWidth },
                }}
                open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box
             display='flex'
             flexDirection="column"
             sx={{
                background: "#f5f5f5",
                justifyContent: 'space-between',
                minHeight: '100vh',
                // pt: {
                //     xs: '54px',
                //     sm: '64px'
                // },
                paddingTop: `${toolbarHeight}px`,
                width: '100%',
                maxWidth: {
                    md: `calc(100% - ${menuDrawerWidth}px)`
                }
             }}
            >
                <Box
                component='main'
                sx={{
                    // flexGrow: 1,
                    pl: {
                        sm: 2,
                        md: 3
                    },
                    pr: {
                        sm: 2
                    },
                    py: 2,
                }}
                >
                    {props.children}
                </Box>

                <Box
                component='footer'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 3,
                    color: "GrayText",
                    fontSize: 14,
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                    background: theme => theme.palette.background.paper,
                }}
                >
                    {currentYear} LD Portal
                </Box>
            </Box>
        </Box>

        <RightDrawer
         open={openRightDawer}
         handleLogout={handleLogout}
         onClose={() => {setOpenRightDawer(false)}}
         currentName={currentName}
        />
        {/* NOTIFICATION POPOVER */}
        <Popover
        open={Boolean(anchorElNotifiaction)}
        onClose={()=> {setAnchorElNotifiaction(null)}}
        anchorEl={anchorElNotifiaction}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical:'top',
            horizontal: 'right'
        }}
        elevation={4}
        sx={{mt: 2, mb: 6}}
        slotProps={{
            paper: {
                style: {
                    borderRadius: 10
                }
            }
        }}
        >
            <NotificationComponent closeDrawer={()=> {setAnchorElNotifiaction(null)}}/>
        </Popover>
    </>
}

export default MainDrawer
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react'
import { changePasswordUser, logout_action, verifyAccount } from '../../Helpers/auth';

const ChangePassword = () => {
    const [new_password, setNew_password] = useState("");
    const [username, setUsername] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [disablePassword, setDisablePassword] = useState(false);

    React.useEffect(() => {
      verifyAccount().then((result) => {
          setUsername(result.username);
      }).catch((err) => {
          logout_action();
      });
    }, []);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!new_password) {
            window.alert("New Password can't be empty");
            return;
        }
        setLoading(true);
        changePasswordUser(username, new_password).then(res => {
            window.alert('SUCCESS change password');
            window.location.replace("/");
        }).catch(err => {
            window.alert("Failed to change password, reason: "+err.message);
            window.location.reload();
        });
    }
    
    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '90vh'}}>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
                <CircularProgress/>
            </Backdrop>
            {username ? <form onSubmit={handleSubmit}><div style={{height: '100%',display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 16, width: 280}}>
                <TextField
                 label='New Password'
                 value={new_password}
                 fullWidth
                 onChange={(e) => {setNew_password(e.target.value)}}
                 placeholder="insert new password here"
                 type={disablePassword ? "text" : 'password'}
                 InputProps={{
                     endAdornment: <InputAdornment position='end'>
                        <IconButton onClick={() => setDisablePassword(!disablePassword)}>
                         {disablePassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                     </InputAdornment>
                 }}
                />
                <Button type='submit' variant='contained'>Change Password</Button>
            </div></form> : <h2>Loading Credential...</h2>}
        </Box>
    )
}

export default ChangePassword;
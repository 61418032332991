import { URL_MASTER_MENU } from "../Config/url_api";
import { formatErrorFetch, getData, postData } from "./tools";

const getDataOptionsForCreateMasterUserMenu = () => new Promise((resolve, reject) => {
    getData(URL_MASTER_MENU + '/dataforcreate/user/menu').then((result) => {
        resolve(result)
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const saveMasterMenuUser = (data, username) => new Promise((resolve, reject) => {
    const body = {
        data,
        username
    };
    postData(URL_MASTER_MENU + '/save', body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const searchUserMenu = (username, isForDrawer) => {
    let url = URL_MASTER_MENU + '/search?username=' + username;
    if (isForDrawer) url += '&isForDrawer=1';
    return getData(url);
}

export {
    getDataOptionsForCreateMasterUserMenu,
    saveMasterMenuUser,
    searchUserMenu
};
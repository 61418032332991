import moment from "moment";
import { URL_FAKTUR, URL_PELUNASAN_FAKTUR } from "../Config/url_api";
import { uploadImageWithCompression } from "./attachment";
import {
    deleteData,
    formatErrorFetch,
    getData,
    getElementValueByID,
    getErrorFetchMsg,
    getErrorFetchResponse,
    postData,
    putData,
    setToStartOfDay
} from "./tools";

const validtionHeaderAndParameter = (header, nilai_pelunasan, attachments) => {
    if (!header.faktur_id) throw Error("Nomor invoice kosong");
    if (!nilai_pelunasan) throw Error("Nilai Pembayaran/Pelunasan Kosong");
    // if (!Array.isArray(attachments)) throw Error("Bukti Pelunasan null");
    // if (attachments.length === 0) throw Error("Bukti Pelunasan kosong");
};

const createNewPelunasan = async (header, nilai_pelunasan, attachments, payment_method, selectedRekening, _other) => {
    validtionHeaderAndParameter(header, nilai_pelunasan, attachments);

    let body_bank = {
        bank_account_no: header.account_no,
        bank_account_name: header.account_name,
        bank_name: header.bank_name
    };

    if (selectedRekening) body_bank = {
        bank_account_no: selectedRekening.account_no,
        bank_account_name: selectedRekening.account_name,
        bank_name: selectedRekening.bank_name
    }

    const uploadResult = (Array.isArray(attachments) && attachments.length > 0) ? await Promise.all(attachments.map(att => uploadImageWithCompression(att, "PELUNASAN"))) : [];
    const body = {
        no_invoice: header.faktur_id,
        nilai_pelunasan,
        bukti_pelunasan: uploadResult,
        customer_code: header.customer_code,
        bank_account_no: body_bank.bank_account_no,
        payment_method,
        _other: {
            ..._other,
            customer_name: header.customer_name,
            customer_city: header.customer_city,
            bank_account_name: body_bank.bank_account_name,
            bank_name: body_bank.bank_name,
        }
    };

    const result = await postData(URL_PELUNASAN_FAKTUR + "/Create", body);
    return result;
};

const createPelunasan2 = async (json) => {
    const result = {
        status: "SUCCESS",
        submitResponse: {
            docno: "",
        }
    }

    try {
        const uploadResults = (Array.isArray(json.bukti_pelunasan) && json.bukti_pelunasan.length > 0) ? await Promise.all(json.bukti_pelunasan.map(att => uploadImageWithCompression(att, "PELUNASAN"))) : [];
        json.bukti_pelunasan = uploadResults;
    } catch (error) {
        result.status = "ERROR";
        result.submitResponse = {
            status: 500,
            message: "Gagal untuk upload bukti pembayaran",
        };
        return result;
    }

    try {
        const response = await postData(URL_PELUNASAN_FAKTUR + "/create/2", json);
        result.submitResponse = response;
    } catch (error) {
        result.status = "ERROR";
        result.submitResponse = getErrorFetchResponse(error);
    }
    return result;
};

const editPelunasan2 = async (json) => {
    const result = {
        status: "SUCCESS",
        submitResponse: {
            docno: "",
        }
    }

    try {
        const uploadResults = (Array.isArray(json.bukti_pelunasan) && json.bukti_pelunasan.length > 0) ? await Promise.all(json.bukti_pelunasan.map(att => uploadImageWithCompression(att, "PELUNASAN"))) : [];
        json.bukti_pelunasan = uploadResults;
    } catch (error) {
        result.status = "ERROR";
        result.submitResponse = {
            status: 500,
            message: "Gagal untuk upload bukti pembayaran",
        };
        return result;
    }

    try {
        const response = await putData(URL_PELUNASAN_FAKTUR + "/edit/document", json);
        result.submitResponse = response;
    } catch (error) {
        result.status = "ERROR";
        result.submitResponse = getErrorFetchResponse(error);
    }
    return result;
};

const getDetailPelunasanDocument = async (no_pelunasan) => {
    const result = {
        status: "DONE",
        data: {
            allow_see_all_invoices: null,
            bukti_pelunasan: null,
            current_flow_pic_and_activity: null,
            current_user: null,
            current_user_activity: null,
            deposit_so: null,
            details: [],
            header: {}
        },
        error: {
            status: 0,
            message: null,
        }
    };
    try {
        result.data = await getData(`${URL_PELUNASAN_FAKTUR}/Document?oin=${no_pelunasan}`);
    } catch (error) {
        result.status = "ERROR";
        result.error = getErrorFetchResponse(error);
    }

    return result;
};

const getDataForEditDocumentPelunasan = async (no_pelunasan) => {
    const result = {
        status: "DONE",
        data: {
            header: {},
            details: [],
            bukti_pelunasan: null,
            settings: {
                options_rekening: [],
            }
        },
        error: {
            status: 0,
            message: null,
        }
    };

    try {
        result.data = await getData(`${URL_PELUNASAN_FAKTUR}/document/edit?oin=${no_pelunasan}`);
    } catch (error) {
        result.status = "ERROR";
        result.error = getErrorFetchResponse(error);
    }
    return result;
}

const getListPelunasanDocument = (status_params) => new Promise((resolve, reject) => {
    getData(`${URL_PELUNASAN_FAKTUR}/List/${status_params}`).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const approveT_PFDocument = (no_pelunasan, line_no, parallel_line_no, otherData) => new Promise((resolve, reject) => {
    const body = {
        ...otherData,
        line_no,
        parallel_line_no,
        // tanggal_pencairan: tanggal_pencairan ? setToStartOfDay(tanggal_pencairan).getTime() : null,
    };

    postData(`${URL_PELUNASAN_FAKTUR}/approve/document?oin=${no_pelunasan}`, body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const returnT_PFDcoument = (no_pelunasan, reason, line_no, parallel_line_no) => new Promise((resolve, reject) => {
    const body = {
        oin: no_pelunasan,
        reason,
        line_no,
        parallel_line_no,
    };
    postData(`${URL_PELUNASAN_FAKTUR}/return/document`, body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const rejectT_PFDcoument = (no_pelunasan, reason, line_no, parallel_line_no) => new Promise((resolve, reject) => {
    const body = {
        oin: no_pelunasan,
        reason,
        line_no,
        parallel_line_no
    };
    postData(`${URL_PELUNASAN_FAKTUR}/reject/document`, body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const deleteT_PFDocument = (no_pelunasan, reason) => new Promise((resolve, reject) => {
    const body = {
        oin: no_pelunasan,
        reason
    };
    deleteData(`${URL_PELUNASAN_FAKTUR}/delete/document`, body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const editT_PFDocument = async (body, deleted_old_bukti_pelunasan, no_pelunasan) => {
    const url = `${URL_PELUNASAN_FAKTUR}/edit/document`;
    const searchParams = {
        oin: no_pelunasan,
    };

    body.bukti_pelunasan = await Promise.all(body.bukti_pelunasan.map(att => uploadImageWithCompression(att, 'PELUNASAN')));

    const json = {
        newData: body,
        deleted_old_bukti_pelunasan,
    };

    return putData(url, json, searchParams);
};

const pembulatanFaktur = (faktur_id, gl_account_code, notes) => new Promise((resolve, reject) => {
    const body = {
        oin: faktur_id,
        notes,
        account_code: gl_account_code
    };
    postData(`${URL_PELUNASAN_FAKTUR}/pembulatanfaktur`, body).then((result) => {
        resolve(result)
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const getListClosedPelunasan = (no_invoice) => new Promise((resolve, reject) => {
    getData(`${URL_PELUNASAN_FAKTUR}/List/CLOSED?invoice=${no_invoice}`).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const getCountReturnedPelunasanDocs = async () => {
    const res = await getData(URL_PELUNASAN_FAKTUR + '/count/returned/sales/docs');
    return res.data;
};

const getCountPelunasanDocument = (status = "") => new Promise((resolve, reject) => {
    status = status.toUpperCase();
    getData(`${URL_PELUNASAN_FAKTUR}/list/count/${status}`).then((result) => {
        resolve(result.data);
    }).catch((err) => {
        reject(formatErrorFetch(err));
    });
});

const getCountGiroPelunasanOverdue = () => {
    return getData(URL_PELUNASAN_FAKTUR + '/count/giro/overdue');
};

const checkBeforeApprove = (no_pelunasan) => {
    return getData(URL_PELUNASAN_FAKTUR + "/before_approve?oin=" + (no_pelunasan || ""));
};

function getFormPelunasanValue(options_rekening, selectedInvoices, nominalPembayaran) {
    const metode_pembayaran = document.querySelector(`input[name = "submit-pelunasan-metode-pembayaran"]:checked`)?.value;
    const rekening_tujuan_value = getElementValueByID("submit-pelunasan-rekening-tujuan");

    const tanggal_transfer_value = getElementValueByID("submit-pelunasan-tanggal-transfer");
    const transferred_from = {
        transferred_no: getElementValueByID("submit-pelunasan-no-rekening-transfer-from"),
        transferred_name: getElementValueByID("submit-pelunasan-nama-rekening-transfer-from"),
        transferred_bank: getElementValueByID("submit-pelunasan-nama-bank-transfer-transfer-from"),
    };
    const notes = getElementValueByID("submit-pelunasan-notes");
    // const total_nilai_pelunasan = getElementValueByID('submit-pelunasan-total-terbayar');

    const tanggal_efektif_giro = getElementValueByID("submit-pelunasan-tanggal-efektif-giro");
    const tanggal_terbit_giro = getElementValueByID("submit-pelunasan-tanggal-terbit-giro");
    const giro_info = {
        bilyet_giro_no: getElementValueByID("submit-pelunasan-bilyet-giro"),
        giro_bank_name: getElementValueByID("submit-pelunasan-nama-giro-transfer-from"),
        giro_bank_branch: getElementValueByID("submit-pelunasan-cabang-giro-transfer-from"),
        tanggal_efektif_giro: (tanggal_efektif_giro) ? setToStartOfDay(moment(tanggal_efektif_giro, "DD/MM/YYYY").toDate()).getTime() : null,
        tanggal_terbit_giro: (tanggal_terbit_giro) ? setToStartOfDay(moment(tanggal_terbit_giro, "DD/MM/YYYY").toDate()).getTime() : null
    }
    const tanggal_pembayaran = getElementValueByID("submit-pelunasan-tanggal-pembayaran");

    const account_no = (typeof rekening_tujuan_value === "string") ? rekening_tujuan_value.split(" ")[0] : "";

    let total_pembayaran_baru = 0;
    const list_invoices = selectedInvoices.map(inv => {
        const value_input = getElementValueByID(`submit-pelunasan-nilai-pembayaran-${inv.faktur_id}`);
        // const nilaiNya = parseCurrToNumber(value_input);
        inv.nilai_pelunasan = Number(value_input);
        total_pembayaran_baru += (inv.nilai_pelunasan);
        return inv;
    });
    // const total_nilai_pelunasan_val = parseCurrToNumber(total_nilai_pelunasan);
    const total_nilai_pelunasan_val = Number(nominalPembayaran);

    return {
        total_nilai_pelunasan: total_nilai_pelunasan_val,
        metode_pembayaran,
        giro_info,
        notes,
        rekening_tujuan: options_rekening.find(rek => rek.account_no === account_no),
        tanggal_transfer_value: tanggal_transfer_value ? setToStartOfDay(moment(tanggal_transfer_value, "DD/MM/YYYY").toDate()).getTime() : null,
        transferred_from,
        tanggal_pembayaran: (tanggal_pembayaran) ? setToStartOfDay(moment(tanggal_pembayaran, "DD/MM/YYYY").toDate()).getTime() : null,
        total_pembayaran_baru,
        list_invoices,
        sisa_pelunasan_tak_terpakai: (total_nilai_pelunasan_val - total_pembayaran_baru) - 0,
    }
}

async function getCreatePelunasnSettings() {
    const result = {
        status: "DONE",
        data: {
            options_rekening: [],
        },
        error: {
            status: 500,
            message: "Internal Server Error"
        },
    }
    try {
        const resp = await getData(URL_PELUNASAN_FAKTUR + "/create/settings");
        result.data = resp;
    } catch (error) {
        result.status = "ERROR";
        result.error = getErrorFetchResponse(error);
    }
    return result;
}

async function getFakturInfoForCreatePelunasan(faktur_id, exception_no_pelunasan) {
    const result = {
        status: "DONE",
        faktur_id,
        data: {},
        error: null,
    };

    try {
        const response = await getData(URL_FAKTUR + "/Detail", false, 0, {
            oin: faktur_id,
            oin_ref: exception_no_pelunasan,
        });
        result.data = response
    } catch (error) {
        result.status = "ERROR";
        result.error = getErrorFetchResponse(error);
    }
    return result;
}

async function getFakturInfoForlPelunasan(faktur_id, isViewAll, no_pelunasan_pengecualian) {
    const result = {
        status: "DONE",
        data: {},
        errorMessage: null,
    };

    const url = URL_FAKTUR + "/detail/simple";
    const searchParams = {
        oin: faktur_id,
        poe: isViewAll ? "TINGOK" : "LEOA"
    }
    if (no_pelunasan_pengecualian) {
        // url.searchParams.set("enp", no_pelunasan_pengecualian);
        searchParams.enp = no_pelunasan_pengecualian;
    }
    // const poe = isViewAll ? "TINGOK" : "LEOA";

    try {
        // const response = await getData(`${URL_FAKTUR}/detail/simple?oin=${faktur_id}&poe=${poe}`);
        const response = await getData(url, false, 0, searchParams);
        result.data = response
    } catch (error) {
        result.status = "ERROR";
        result.errorMessage = getErrorFetchMsg(error);
    }
    return result;
}

const cancelApprovalPembayaranFaktur = (no_pelunasan, reason) => new Promise((resolve, reject) => {
    const body = {
        oin: no_pelunasan,
        reason
    };
    postData(`${URL_PELUNASAN_FAKTUR}/cancel/approval`, body).then((result) => {
        resolve(result);
    }).catch((err) => {
        reject(getErrorFetchResponse(err));
    });
});

export {
    approveT_PFDocument,
    cancelApprovalPembayaranFaktur,
    checkBeforeApprove,
    createNewPelunasan,
    createPelunasan2,
    deleteT_PFDocument,
    editPelunasan2,
    editT_PFDocument,
    getCountGiroPelunasanOverdue,
    getCountPelunasanDocument,
    getCountReturnedPelunasanDocs,
    getCreatePelunasnSettings,
    getDataForEditDocumentPelunasan,
    getDetailPelunasanDocument,
    getFakturInfoForCreatePelunasan,
    getFakturInfoForlPelunasan,
    getFormPelunasanValue,
    getListClosedPelunasan,
    getListPelunasanDocument,
    pembulatanFaktur,
    rejectT_PFDcoument,
    returnT_PFDcoument
};
